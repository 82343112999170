window.jQuery = window.$ = require('jQuery')

$(function() {
  //スクロール位置が300でボタンを表示
  var windowH = $(window).height()
  $(window).scroll(function() {
      var sTop = $(this).scrollTop()
      if (sTop > 200) {
        $('#pageTop').addClass('is-show');
      } else {
        $('#pageTop').removeClass('is-show');
      }
  });

  $('#btn-menu').on('click', function () {
    $(this).toggleClass('open')
    $('nav').fadeToggle()
    $('body').toggleClass('fixed')
  })

  $('#pageTop').on('click', function (e) {
    e.preventDefault()
    $('body,html').animate({
      scrollTop: 0
    }, 300);
  })

  if ($(".top-scroll-wrapper").length) {
    $(".top-scroll-wrapper .scrollbar").width($('.subject-info-table').width())
  }

  $(".top-scroll-wrapper").scroll(function () {
    $(".subject-table-wrapper")
      .scrollLeft($(".top-scroll-wrapper").scrollLeft());
  });
  $(".subject-table-wrapper").scroll(function () {
    $(".top-scroll-wrapper")
      .scrollLeft($(".subject-table-wrapper").scrollLeft());
  });
})